<template>
    <div class="profile-page">
        <AlertMenu />   
        <div v-if="!loading" class="profile-container">
            <div class="title">
                <h1 class="authentication-title alert-page-title">
                    MANAGE YOUR ACCOUNT
                </h1>
                <Divider />
            </div>
            <section class="section">
                <div class="section-title">
                    <img src="/images/icons/profile.svg" alt="Profile Update">
                    <h2 class="authentication-title">Update Profile</h2>
                </div>
            </section>
            <FormulateForm @submit="updateUser" name="updateUser">
            <div class="input-container">
                <Label>Change Email:</Label>
                <FormulateInput
                    v-model="user.data.email"
                    type="email"
                    name="identifier"
                    placeholder="Enter Email" 
                    class="input"
                    validation="required|email"
                    error-behavior="value"
                    @input="changed = true"
                />
            </div>
            <div class="input-container">
                <Label >Change Password:</Label>
                <FormulateInput
                    type="password"
                    name="password"
                    class="input"
                    v-model="password"
                    @input="changed = true"
                    :ignored="password === '' && oldPassword === ''"
                    error-behavior="value"
                    placeholder="Enter New Password"
                    validation="^required|min:8,length|checkLetters|checkSpecialCharacters"
                    :validation-rules="{
                        checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                        checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                    }"
                    validation-name="Password"
                    :validation-messages="{
                        checkLetters: 'Password requires at least 1 number and 1 letter.',
                        checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                    }"
                />
            </div>
            <div class="input-container">
                <label></label>
                <FormulateInput
                    type="password"
                    name="password"
                    class="input"
                    v-model="oldPassword"
                    @input="changed = true"
                    :ignored="password === '' && oldPassword === ''"
                    error-behavior="value"
                    placeholder="Existing Password"
                    validation="^required|min:8,length|checkLetters|checkSpecialCharacters"
                    :validation-rules="{
                        checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                        checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                    }"
                    validation-name="Password"
                    :validation-messages="{
                        checkLetters: 'Password requires at least 1 number and 1 letter.',
                        checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                    }"
                />
            </div>
            <Divider></Divider>
            <section class="section">
                <div class="section-title">
                    <img src="/images/icons/bell.svg" alt="Profile Update">
                    <h2 class="authentication-title">Notification Preferences</h2>
                </div>
            </section>
            <div class="field-checkbox">
                <FormulateInput
                    name="alert"
                    type="checkbox"
                    class="checkbox"
                    @change="changed = true"
                    v-model="user.data.alert"
                    />
                <label class="paragraph" for="alert"><b>Property Alerts:</b> Send me emails for new properties matching my criteria. (Without this option, new matches won't be emailed, but can be viewed in my account).</label>
            </div>
            <div class="field-checkbox">
                <FormulateInput
                    name="newsletter"
                    type="checkbox"
                    class="checkbox"
                    @change="changed = true"
                    v-model="user.data.newsletter"
                    />
                <label class="paragraph" for="newsletter"><b>CZRE Newsletter:</b> stay up-to-date with the latest property listings, real estate news, and market trends. Get the insight you need, delivered straight to your inbox!</label>
            </div>
            </FormulateForm>
            <Divider></Divider>
            <section class="section">
                <div class="section-title">
                    <img src="/images/icons/cross-square.svg" alt="Profile Update">
                    <h2>Delete Account</h2>
                </div>
            </section>
            <div class="delete-section">
                <p>
                    Please type 'delete' in the box to confirm account deletion. Be aware, this action is permanent and cannot be undone. All personal data and account settings will be permanently removed from our system.
                </p>
                <FormulateInput
                    type="text"
                    v-model="deleteText"
                    class="input delete-input"
                    placeholder="delete"
                    validation="checkDelete"
                    :validation-rules="{
                        checkDelete: ({ value }) => value === 'delete',
                    }"
                    validation-name="Delete"
                    error-behavior="value"
                    :validation-messages="{
                        checkDelete: 'Your must type the word\'delete\' in the input box.',
                    }"
                    @change="changed = true"
                />
                <Button class="delete-button btn orange-bg" @click="deleteAccount" :disabled="deleteText !== 'delete'">
                    DELETE ACCOUNT
                </Button>
            </div>
            <Divider />
            <div class="button-container">
                <Button class="btn black-bg" @click="exit">
                    Exit
                    <img src="/images/icons/cross.svg" alt="">
                </Button>
                <Button class="btn orange-bg" @click="triggerSubmit">
                    Save Changes
                    <img src="/images/icons/save.svg" alt="">
                </Button>
            </div>
        </div>
        <Dialog modal :visible.sync="showWarningModal" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    UNSAVED CHANGES
                </h1>
                <Divider />
                <p class="modal-content">
                    Exiting now will discard any changes made to your alert settings. Please save your changes to ensure your alert preferences are updated.
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="$router.push('/account')">
                        Exit Anyway
                    </Button>
                    <Button class="btn orange-bg" @click="showWarningModal = false">
                        Continue Editing
                    </Button>
                </div>
            </div>
        </Dialog>
        <Toast />
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
import AlertMenu from '../../components/AlertMenu.vue'
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog'
import Toast from 'primevue/toast';

export default {
    name: "Profile",
    components: {
        AlertMenu,
        Divider,
        Button,
        Toast,
        Dialog
    },
    data(){
        return{
            apiURL,
            user: {},
            changed: false,
            deleteText: '',
            password: '',
            oldPassword: '',
            loading: true,
            showWarningModal: false,
            change: false,
        }
    },
    metaInfo: {
        title: 'Manage your account',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    async mounted(){
        this.user = await this.getUserInfo();
        this.loading = false;
    },
    methods: {
        async getUserInfo() {
            try {
                const header = this.$store.getters.authHeader;
                const data = await this.axios.get(`${apiURL}/api/users/me`,header);
                return data;
            } catch(e) {
                this.error = true;
            }
            return {};
        },
        triggerSubmit(){
            this.$formulate.submit('updateUser');
            this.password = '';
            this.oldPassword = '';
            this.$toast.add({severity:'success', summary: 'Information updated', detail: 'Your information has been updated.', life: 3000});
            this.$router.push('/account');
        },
        async updateUser() {
            try {
                const header = this.$store.getters.authHeader;

                const payload = {
                    email: this.user.data.email,
                    alert: this.user.data.alert,
                    newsletter: this.user.data.newsletter
                }

                if(this.oldPassword && this.password){
                    const passwordChange = {
                        currentPassword: this.oldPassword,
                        password: this.password,
                        passwordConfirmation: this.password,
                    }
                    await this.axios.post(`${apiURL}/api/auth/change-password`,passwordChange, header);
                }

                await this.axios.put(`${apiURL}/api/users/user-update/${this.user.data.id}`, payload, header);
                // close dialog, go to my profile or my alert (because logged in)
            } catch(e) {
                this.error = true;
            } 
        },
        async deleteUser() {
            try {
                const header = this.$store.getters.authHeader;

                await this.axios.delete(`${apiURL}/api/users/self-delete/${this.user.data.id}`, header);
                // close dialog, go to my profile or my alert (because logged in)
            } catch(e) {
                this.error = true;
            } 
        },
        deleteAccount(){
            if(this.deleteText === 'delete'){
                this.deleteUser();
            }
        },
        exit(){
            if(this.changed){
                this.showWarningModal = true;
            }else{
                this.$router.push('/account');
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.profile-container{
    background: hsl(0, 0%, 96%);
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.profile-form-container{
    padding: 80px 20px;
}

.title{
    text-align: center;
}

.section{
    margin-left: 30px;
}
.section-title{
    display: flex;
    align-items: center;
    h2{
        margin-left: 20px;
    }
}

.input-container{
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    label{
        width: 20%;
        text-align: right;
        margin-right: 1rem;
        margin-top: 8px;
    }
    .input{
        width: 300px;
        max-width: 100%;
    }
}

.field-checkbox{
    margin-left: 60px;
    display: flex;
    margin-top: 10px;

    max-width: 600px;
}

.checkbox{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
}

.delete-input{
    margin-left: 60px;
}

.delete-section{
    p{
        margin-left: 60px;
        max-width: 600px;
    }
}

.delete-button{
    margin-left: 60px;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 40px 0;
}

::v-deep .formulate-input[data-classification=box] .formulate-input-element input[type=checkbox]:checked ~ .formulate-input-element-decorator{
    border-color: #EF6A40;
}

::v-deep .formulate-input[data-classification=box] .formulate-input-element input[type=checkbox]:checked ~ .formulate-input-element-decorator::before{
    background-color: #EF6A40;
}

::v-deep .formulate-input[data-classification=box] .formulate-input-element input:focus ~ .formulate-input-element-decorator{
    border-color: #EF6A40;
}


@media (max-width: $mobile) {
    .profile-container{
        padding: 0 40px;
    }
    .section{
        margin-left: 0;
    }
    .field-checkbox{
        margin-left: 0px;
    }
    .delete-input{
        margin-left: 0;
    }

    .delete-section{
        p{
            margin-left: 0;
        }
    }
    .delete-button{
        margin-left: 0;
    }
    .input-container{
        label{
            width: 30%;
            margin-top: 0;
        }
        .input{
            width: 250px;
        }
    }
    ::v-deep .p-divider.p-divider-horizontal{
        margin-top: 32px;
    }
}
</style>